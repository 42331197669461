import copos from "../images/copos.webp";
import Carousel from "./Carousel";
import bed1 from "../images/bed1.webp";
import bed2 from "../images/bed2.webp";
import bed3 from "../images/bed3.webp";
import bed4 from "../images/bed4.webp";
import bed5 from "../images/bed5.webp";

const images = [
  {
    image: bed1,
    title: "SUÍTE MASTER",
    description: ["1 cama de casal tamanho King"],
  },
  {
    image: bed2,
    title: "SUÍTE INTERNA 2",
    description: ["1 cama de casal tamanho King + TV"],
  },
  {
    image: bed3,
    title: "SUÍTE INTERNA 3",
    description: ["1 cama de casal tamanho Queen + TV"],
  },
  {
    image: bed4,
    title: "SUÍTE INTERNA 4",
    description: ["2 camas de solteiro uma delas com bicama e TV"],
  },
  {
    image: bed5,
    title: "SUITE EXTERNA 5",
    description: ["2 camas de solteiro uma delas com bicama e TV"],
  },
];

const properties = {
  prevArrow: (
    <button className="border-0 bg-transparent">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
        <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button className="border-0 bg-transparent fw-bold">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
      </svg>
    </button>
  ),
};

export default function Suites() {
  return (
    <section id="suites" className="m-lg-5 py-5">
      <div className="m-auto" style={{ width: "fit-content" }}>
        <span className="m-0 fs-4 font-regular">nossas</span>
        <h1 className="mb-4 redwood-hollow fs-big-title" style={{ opacity: "0.2", lineHeight: "0.7" }}>
          SUÍTES
        </h1>
      </div>
      <div className="d-lg-flex align-items-center gap-5 pb-5 mb-5">
        <img src={copos} alt="copos" className="col-12 col-lg-6" loading="lazy" />
        <div className="mx-4 mx-lg-0 mt-4 mt-lg-0 text-center">
          <span className="fs-text-4 font-regular">
            A casa é composta por 5 suítes que acomodam 12 hóspedes, todas elas com guarda-roupa e cômoda. As suítes estão configuradas da seguinte
            forma:
          </span>
        </div>
      </div>
      <Carousel images={images} properties={properties} topSpace={true} />
      <div className="my-border w-25 m-auto"></div>
    </section>
  );
}
