import social1 from '../images/social1.webp';
import social2 from '../images/social2.webp';
import social3 from '../images/social3.webp';
import social4 from '../images/social4.webp';
import social5 from '../images/social5.webp';
import social6 from '../images/social6.webp';

const images = [social1, social2, social3, social4, social5, social6];

export default function Social() {
    return (
        <section id="social" className="my-5 text-center">
            <h2 className='spaced font-regular'>SIGA A GENTE NO INSTAGRAM</h2>
            <a href='https://www.instagram.com/casadipietra_villatoscana/' target='_blank' rel='noreferrer'>
                <button className="my-btn my-4 font-regular spaced">LINK PARA O PERFIL</button>
            </a>
            <div className="d-flex flex-wrap justify-content-between align-items-center mx-auto pb-5 my-5 col-10">
                {images.map((image, index) => (
                    <img key={index} src={image} alt="Social" className='col-6 col-lg-2 p-3 py-lg-0' loading='lazy' />
                ))}
            </div>
        </section>
    )
}