import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Wedding from './pages/Wedding';
import Eventos from './pages/Eventos';
import Ensaios from './pages/Ensaios';
import Hospedagem from './pages/Hospedagem';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wedding" element={<Wedding />} />
        <Route path="/eventos" element={<Eventos />} />
        <Route path='/ensaios-fotograficos' element={<Ensaios />} />
        <Route path='/hospedagem' element={<Hospedagem />} />
      </Routes>
    </Router>
  );
}

export default App;