import outside_picture from '../images/outside_picture.webp';

export default function History() {
    return (
        <section id='history' className='main-div2'>
            <h1 className="mb-5 pt-5 text-center font-italic fs-title fs-2">O CHARME DA TOSCANA NO<br></br>INTERIOR DE SÃO PAULO</h1>
            <div className='d-block d-lg-flex align-items-center py-5 mx-lg-5 gap-5'>
                <img src={outside_picture} alt="outside_picture" className='w-100 w-img' loading='lazy' />
                <div className='mx-4 mx-lg-0'>
                    <h2 className='redwood-hollow mt-4 mt-lg-0'>FAÇA PARTE<br></br>DESSA HISTÓRIA</h2>
                    <p className='fs-5 font-regular'>Situada a cerca de 1200m de altitude, com
                        uma vista de 360º de vales e montanhas, a
                        Casa Di Pietra foi toda construída em cantaria,
                        técnica milenar que utiliza blocos de rochas
                        moldados manualmente. </p>
                    <p className='fs-5 font-regular'>A singularidade e beleza do projeto,
                        totalmente inspirado na arquitetura típica
                        da região da Toscana, fará com que se
                        sinta verdadeiramente em uma Vila Italiana.</p>
                </div>
            </div>
        </section>
    )
}