import Header from '../components/Header';
import History from '../components/History';
import Experiences from '../components/Experiences';
import Suites from '../components/Suites';
import Footer from '../components/Footer';
import Social from '../components/Social';
import WhatsAppButton from '../components/WhatsAppButton';

export default function Home() {
    return (
        <div>
            <Header />
            <History />
            <Experiences />
            <Suites />
            <Social />
            <Footer />
            <WhatsAppButton />
        </div>
    );
}