import { isMobile } from 'react-device-detect';
import whatsappIcon from '../icons/whatsapp-green.svg';

export default function WhatsAppButton() {
    const apiUrl = isMobile ? 'https://wa.me/+351917368926' : 'https://web.whatsapp.com/send/?phone=%2B351917368926&text&type=phone_number&app_absent=0';
    return (
        <a href={apiUrl} target='_blank' rel='noreferrer' className='position-fixed bottom-0 end-0 bg-white m-3 px-3 py-2 d-flex align-items-center rounded-pill justify-content-center text-decoration-none'>
            <img src={whatsappIcon} alt="Whatsapp" style={{ width: '35px' }} />
        </a>
    )
}