import { isMobile } from 'react-device-detect';
import phoneIcon from '../icons/phone.svg';
import whatsappIcon from '../icons/whatsapp.svg';
import locationIcon from '../icons/location.svg';
import instagramIcon from '../icons/instagram.svg';
import facebookIcon from '../icons/facebook.svg';
import casaDiPietraLogo from '../images/casa_di_pietra_logo_white.webp';
import linkedinIcon from '../icons/linkedin.webp';
import tiktokIcon from '../icons/tiktok.webp';
import spotifyIcon from '../icons/spotify.webp';

export default function Footer() {
    const apiUrl = isMobile ? 'https://wa.me/+351917368926' : 'https://web.whatsapp.com/send/?phone=%2B351917368926&text&type=phone_number&app_absent=0';
    return (
        <footer className='bg-green py-5 text-light d-flex flex-column align-items-center'>
            <h4 className='font-regular'>CONTATOS</h4>
            <div className='d-flex align-items-center gap-2 my-2'>
                <img src={phoneIcon} className='icon' alt='phone icon' height='15px' />
                <div className='d-flex flex-column align-items-center'>
                    <small className='lh-1 font-regular'>Comercial</small>
                    <small>+351 917 368 926</small>
                </div>
            </div>
            <a href={apiUrl} target='_blank' rel='noreferrer' className='d-flex align-items-center gap-2 my-2 text-decoration-none'>
                <img src={whatsappIcon} className='icon' alt='whatsapp icon' height='20px' />
                <small>Clique para WhatsApp</small>
            </a>
            <div className='d-flex align-items-center gap-2 mb-2'>
                <img src={locationIcon} className='icon' alt='location icon' height='20px' />
                <div className='d-flex flex-column align-items-center pt-2'>
                    <small className='lh-1'>Estrada Municipal Hilda Beraldi de Almeida Km 5.5,</small>
                    <small>Serra Negra - SP, 13930-000</small>
                </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center gap-4 my-3 py-4 border-top border-bottom border-white border-2 col-10 col-lg-6'>
                <a href='https://www.instagram.com/casadipietra_villatoscana/' target='_blank' rel='noreferrer' className='text-decoration-none col-5 col-lg-2'><small className='font-regular d-flex align-items-center gap-1'><img src={instagramIcon} alt='instagram_icon' height='20px' />INSTAGRAM</small></a>
                <a href='https://www.facebook.com/profile.php?id=61550911688024' target='_blank' rel='noreferrer' className='text-decoration-none col-5 col-lg-2'><small className='font-regular d-flex align-items-center gap-1'><img src={facebookIcon} alt='facebook_icon' height='20px' />FACEBOOK</small></a>
                <a href='https://www.linkedin.com/in/camila-moreira-6292224/' target='_blank' rel='noreferrer' className='text-decoration-none col-5 col-lg-2'><small className='font-regular d-flex align-items-center gap-1'><img src={linkedinIcon} alt='linkedin_icon' height='20px' />LINKEDIN</small></a>
                <a href='https://www.tiktok.com/@oficial.casadipietra' target='_blank' rel='noreferrer' className='text-decoration-none col-5 col-lg-2'><small className='font-regular d-flex align-items-center gap-1'><img src={tiktokIcon} alt='tiktok_icon' height='20px' />TIKTOK</small></a>
                <a href='https://open.spotify.com/playlist/60JSBFTK6nEbqvLwyEcTnU?si=994c2e72102d4ccf' target='_blank' rel='noreferrer' className='text-decoration-none col-5 col-lg-2'><small className='font-regular d-flex align-items-center gap-1'><img src={spotifyIcon} alt='spotify_icon' height='20px' />SPOTIFY</small></a>
            </div>
            <small className='font-regular'>Todos os direitos reservados ©2024</small>
            <img src={casaDiPietraLogo} alt='Casa Di Pietra Logo' className='col-4 col-lg-2' loading='lazy' />
        </footer>
    )
}