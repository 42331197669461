import logo from '../images/casa_di_pietra_logo.webp';

export default function Header() {
    return (
        <section className='main-div d-flex flex-column justify-content-between align-items-center flex-wrap'>
            <div className="mx-auto d-flex align-items-center flex-column">
                <img src={logo} alt="logo" className='col-7 col-lg-3' />
                <div className="d-flex justify-content-center border-top border-white border-2 pt-2 font-regular spaced text-center">
                    <span>
                        <a href='#history' className='text-decoration-none text-inherit mx-1'>HISTÓRIA</a> | <a href='#experiences' className='text-decoration-none text-inherit mx-1'>EXPERIÊNCIAS</a> | <a href='#suites' className='text-decoration-none text-inherit mx-1'>SUÍTES</a> | <a href='#social' className='text-decoration-none text-inherit mx-1'>CONTATOS</a>
                    </span>
                </div>
            </div>
            <button className='mb-4 col-6 col-lg-2 btn border-white blur'>Faça já sua locação</button>
        </section>
    );
}